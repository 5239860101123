import ApiService from "./ApiService";


export async function apiGetProducts(data) {
    return ApiService.fetchData({
        url: '/uspfproducts?twitter=true',
        method: 'post',
        data,
    })
}
export async function apiGetUSPFProducts(data) {
    return ApiService.fetchData({
        url: '/uspfproducts?twitter=true',
        method: 'post',
        data,
    })
}
export async function apiGetupdateUSPFProduct(data) {
    return ApiService.fetchData({
        url: '/updateUspfProduct?twitter=true',
        method: 'post',
        data,
    })
}
export async function apiGetShopProducts(data) {
    return ApiService.fetchData({
        url: '/shop/products?twitter=true',
        method: 'post',
        data,
    })
}

export async function apiGetShopProductData(data) {
    return ApiService.fetchData({
        url: '/shop/product-info?twitter=true',
        method: 'post',
        data,
    })
}

export async function apiCreateOrder(data) {
    return ApiService.fetchData({
        url: '/shop/create-order?twitter=true',
        method: 'post',
        data,
    })
}
export async function apiGetuserOrders(data) {
    return ApiService.fetchData({
        url: '/user/orders',
        method: 'post',
        data,
    })
}
export async function apiDeleteUserOrder(data) {
    return ApiService.fetchData({
        url: '/shop/create-order?twitter=true',
        method: 'post',
        data,
    })
}