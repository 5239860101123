
import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import display1 from "assets/images/features/display-5.jpg";
import display2 from "assets/images/features/display-2.jpg";
import display3 from "assets/images/features/display-3.jpg";
import { Button, Dialog } from "components/ui";
import { useState } from "react";
import { Link } from "react-router-dom";
import ConnectBtn from "components/buttons/ConnectBtn";
import { HiChevronRight } from "react-icons/hi";
import { BsCheck2Circle } from "react-icons/bs";

const HomeDisplay = () => {
   const [dialogIsOpen, setIsOpen] = useState(false);
   const whyInfo = ["Better prices","100% Free to use","24-48 Hours Turnaround time"]
   useEffect(() => {
    // openDialog()
   
     return () => {
      
     }
   }, [])
   

   const openDialog = () => {
       setIsOpen(true)
   }

   const onDialogClose = (e) => {
       console.log('onDialogClose', e)
       setIsOpen(false)
   }

   const onDialogOk = (e) => {
       console.log('onDialogOk', e)
       setIsOpen(false)
   }
      // Custom navigation arrow components
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        onClick={onClick}
      />
    );
  };

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        onClick={onClick}
      />
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
    pauseOnHover: true,
    centerPadding: 0,
    slidesToScroll: 1,
    centerMode: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    
  };
  return (
    <>
<div className="slider-container">
    <Slider {...settings}>
      <div className="item mx-6">
      
        <div className="flex items-start justify-start h-full flex-col pt-[36px]">
          
          <h1 className="font-bold text-[30px] md:text-[36px] capitalize mb-2 relative z-10 text-uspfblue">Affordable Print-on-Demand Solution</h1>
          {/* <p className=" text-lg max-w-[600px] mt-6">
          We provide the most affordable Print On Demand services in the market without sacrificing any of the quality. Whether it’s Shopify, Etsy, or Private Contracts - reach out to see how you can save more for the same DTG services you’re currently using.
            </p> */}
            <div className="flex flex-col items-start mt-4  px-2 justify-start space-y-4 ">
            {
                whyInfo.map(item =>
                    <div className="flex flex-col whystore !max-w-full flex-1">
                    <div className="flex items-start flex-col w-full ">
                       <div className="flex flex-row space-x-4 items-center">
                        <BsCheck2Circle  color="" className={"text-red-700 textheadicon"}/>
                        <h1 className="textallhead font-bold">{item}</h1></div> 
                       
                    </div>
                   
                    </div>

                )
            }
           
           <div className=" flex items-center justify-center slideactions !mt-9 w-full">
            <Link to={`https://usprintfactory.com/app/`} target="_blank" className=" mx-2 mb-2 w-full">
              <ConnectBtn block={true} text="SIgn Up Here" icon={<HiChevronRight className='bg-white text-red-600 rounded-full' size={28} />} />
              </Link>
              <Link
                    to={"/pages/contact" }
                    className="text-white hover:text-custom duration-300   mx-2 mb-2 w-full block bg-white/5 text-lg text-center"
                  ><Button variant=""
                  color="yellow-600" block className="border-red-600 border-1 text-black !rounded !px-3 !h-12 !py-3 flex items-center justify-between "> <span className='flex-1 text-center'>
                  <span className='px-8 font-extrabold text-2xl uppercase'>Contact Us</span>
              </span></Button></Link>
                </div>
          </div>
        </div>
        <div><img src={display1} className={"w-3/4 mx-auto"}/></div>
       
      </div>
      {/* <div className="item  mx-6">
       
        <div className="flex items-start justify-center h-full flex-col pt-[36px]">
          <h1 className="font-bold text-[48px] md:text-[48px] capitalize mb-2 relative z-10 text-uspfred">State of the Art Facility</h1>
          <h1 className="font-bold text-[30px] md:text-[36px] capitalize mb-2 relative z-10 text-uspfblue">In-house Production</h1>
          <p className=" text-lg max-w-[600px] mt-6">
          With our state-of-the-art Brother DTG Pro equipment along with Brown Firefly system we can produce amazing digital prints with unbeatable color vibrancy! Whether you are selling 1 or 1000+ shirts per day we have the complete system and capacity to print and fulfill your orders seamlessly.
            </p>
            <div className="mt-4">
                  <p className="mb-0 text-muted">
                    <span className="w-2 h-2 inline-block rounded-full mr-2 relative top-[-2px] bg-custom"></span>
                    US based location.
                  </p>
                  <p className="mb-0 mt-2 text-muted">
                    <span className="w-2 h-2 inline-block rounded-full mr-2 relative top-[-2px] bg-custom"></span>
                    Worlwide delivery.
                  </p>
                  
                </div>
        </div>
        <div><img src={display2} className={"w-75"}/></div>
       
      </div>
      <div className="item mx-6">
       
        <div className="flex items-start justify-center h-full flex-col pt-[36px]">
          <h1 className="font-bold text-[48px] md:text-[48px] capitalize mb-2 relative z-10 text-uspfred">Reliable Delivery Partners</h1>
          <h1 className="font-bold text-[30px] md:text-[36px] capitalize mb-2 relative z-10 text-uspfblue">Quick Turnarounds</h1>
          <p className=" text-lg max-w-[600px] mt-6">
          Our trusted network of delivery partners ensures prompt and dependable services for all your shipping needs. With a proven track record of timely deliveries and secure handling, you can count on us to get your goods to their destination safely and efficiently. Rest assured, your shipments are in capable hands with our reliable delivery partners.
            </p>
            <div className="mt-4">
                  <p className="mb-0 text-muted">
                    <span className="w-2 h-2 inline-block rounded-full mr-2 relative top-[-2px] bg-custom"></span>
                    Timely deliveries.
                  </p>
                  <p className="mb-0 mt-2 text-muted">
                    <span className="w-2 h-2 inline-block rounded-full mr-2 relative top-[-2px] bg-custom"></span>
                    Secure handling.
                  </p>
                  <p className="mb-0 mt-2 text-muted">
                    <span className="w-2 h-2 inline-block rounded-full mr-2 relative top-[-2px] bg-custom"></span>
                    Real-time tracking.
                  </p>
                </div>
        </div>
        <div><img src={display3} className={"w-75"}/></div>
       </div> */}
      
    </Slider>
  </div>
  <Dialog
    isOpen={dialogIsOpen}
    onClose={onDialogClose}
    onRequestClose={onDialogClose}
    width={800}
    closable={false}
>
    <h5 className="mb-4 font-bold">Information Update</h5>
    <p>We're reviewing our prices. As you know, costs are rising all over the world and we're no stranger to these increases. From blank apparel to ink, to shipping costs and more, we've seen a significant increase in costs across the board. We're still going to do our best to keep our services as affordable as possible.
                        Please see the prices mentioned below - will be effective 1/8/2024.</p><br/><p>
Thank you for your understanding - please feel free to reach out if you have any questions or concerns.</p>
                <p><img src="https://uspfdashboard.com/js/price-update.png" width="100%" /></p>
               
    <div className="text-right mt-6">
        <Button
            className="btn btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]"
            variant="plain"
            onClick={onDialogClose}
        >
            Okay
        </Button>
        
    </div>
</Dialog></>
  )
}

export default HomeDisplay