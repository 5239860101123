import FooterTwo from 'components/footers/FooterTwo'
import React from 'react'
import WhyDisplay from 'features/home/WhyDisplay'
import WhyContentTwo from 'features/home/WhyContentTwo'


const WhyUSPF = () => {
  return (
    <>
    <section className="md:pt-[80px] md:pb-[100px] pt-[80px] pb-[40px] relative bg-cover bg-center bg-white">
    <div className="absolute inset-0 bg-cover bg-center bg-no-repeat w-full h-full "></div>
        <WhyDisplay />
        
    </section>
    <WhyContentTwo />
    
    <FooterTwo />
    </>
  )
}

export default WhyUSPF