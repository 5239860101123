import React, { useState } from 'react'

import { Field, Form, Formik } from 'formik'
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi'
import * as Yup from 'yup'
import overviewData from 'assets/fake-data/overview'
import { Button, Checkbox, FormContainer, FormItem, Input, Notification, Select, toast } from 'components/ui'
import { apiSendMail } from 'services/ContactService'
import ReCAPTCHA from "react-google-recaptcha";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name Required'),
  email: Yup.string().email('Invalid email').required('Email Required'),
  ordervolume: Yup.string().required('Select order volume'),
  website: Yup.string()
  .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
  )
  .required('Please enter website'),
  phone: Yup.string().matches(/^[6-9]\d{9}$/, {message: "Please enter valid number.", excludeEmptyString: false}),
  message: Yup.string()
      .min(10, 'Too Short!')
      .required('Message Required'),
      captcha: Yup.string()
      .required('Please confirm'),
})
const optionsVolume = [
    { value: '< 100', label: '< 100' },
    { value: '100-499', label: '100-499' },
    { value: '500-999', label: '500-999' },
    { value: '1000-4900', label: '1000-4900' },
    { value: '4900-9999', label: '4900-9999' },
    { value: '> 9999', label: '> 9999' },
]
const ContactForm = ({w,callback,msgType}) => {
    const [Submitting, setsetSubmitting] = useState(false)
    const [captchaDone, setcaptchaDone] = useState(null)
    const onChangeCaptcha = (value,field,form)=>{
        
        setcaptchaDone(value)
        
        form.setFieldValue(
            field.name,
            value
        )
    }
  return (
    <div className={w?"":"max-w-[500px] w-full mx-auto "}>
        <Formik
                initialValues={{
                    name: '',
                    email: '',
                    message: '',
                    phone: '',
                    website:'',
                    ordervolume:'',
                    captcha:''
                }}
                validationSchema={validationSchema}
                onSubmit={async(values, { resetForm, setSubmitting }) => {
                    setsetSubmitting(true);
                    const response = await apiSendMail({...values,inquiryFor:msgType})
                    
                    // setTimeout(() => {
                    //     alert(JSON.stringify(values, null, 2))
                        setSubmitting(false)
                        setsetSubmitting(false);
                       
                        resetForm()
                        const type = "success";
                        toast.push(
                            <Notification
                                title={"Thank You!"}
                                type={type}
                                duration={3000}
                                className="mt-2 text-muted"
                            >
                                We will get back to you soon.
                            </Notification>
                        )
                        if(callback){
                            callback()
                        }

                    //     
                    // }, 400)
                }}
            >
                {({ values,touched, errors, resetForm,setFieldValue }) => (
                    <Form>
                        <FormContainer>
                            <FormItem
                                label="Your Name"
                                invalid={errors.name && touched.name}
                                errorMessage={errors.name}
                            >
                                <Field
                                    type="name"
                                    autoComplete="off"
                                    name="name"
                                    placeholder="Name"
                                    component={Input}
                                />
                            </FormItem>
                            <FormItem
                                label="Email"
                                invalid={errors.email && touched.email}
                                errorMessage={errors.email}
                            >
                                <Field
                                    type="email"
                                    autoComplete="off"
                                    name="email"
                                    placeholder="Email"
                                    component={Input}
                                />
                            </FormItem>
                            <FormItem
                                label="Phone Number"
                                invalid={errors.phone && touched.phone}
                                errorMessage={errors.phone}
                            >
                                <Field
                                    type="phone"
                                    autoComplete="off"
                                    name="phone"
                                    placeholder="Phone"
                                    component={Input}
                                />
                            </FormItem>
                            <FormItem
                                label="Website/ Store Url"
                                invalid={errors.website && touched.website}
                                errorMessage={errors.website}
                            >
                                <Field
                                    type="url"
                                    autoComplete="off"
                                    name="website"
                                    placeholder="Website/ Store Url"
                                    component={Input}
                                />
                            </FormItem>
                           
                            <FormItem
                                label="Current Order Volume"
                                
                                invalid={errors.ordervolume && touched.ordervolume}
                                errorMessage={errors.ordervolume}
                            >
                                <Field name="ordervolume">
                                    {({ field, form }) => (
                                        <Select
                                            field={field}
                                            form={form}
                                            options={optionsVolume}
                                            value={optionsVolume.filter(
                                                (option) =>
                                                    option.value ===
                                                    values.ordervolume
                                            )}
                                            onChange={(option) =>
                                                form.setFieldValue(
                                                    field.name,
                                                    option.value
                                                )
                                            }
                                        />
                                    )}
                                </Field>
                            </FormItem>
                            <FormItem
                                label="Type Message"
                                invalid={errors.message && touched.message}
                                errorMessage={errors.message}
                            >
                                <Field
                                    type="text"
                                    autoComplete="off"
                                    name="message"
                                    placeholder="Message"
                                    as="textarea"
                                   

                                ><textarea placeholder="Message" className="min-h-[150px]" rows="4"  /></Field>
                            </FormItem>
                            <FormItem
                                label=""
                                invalid={errors.captcha && touched.captcha}
                                errorMessage={errors.captcha}
                            >
                                <Field name="captcha">
                                {({ field, form }) => (
                                    <ReCAPTCHA
    sitekey="6Ld7fVwoAAAAAIYgxZh5EvXjZIdz0ey1FWYkXIyQ"
    onChange={(v)=>onChangeCaptcha(v,field,form)}
  />

                                )}
                                 </Field>
                            </FormItem>
                           
                            <FormItem className="flex  flex-row">
                           
                            <Button variant="solid" type="submit" disabled={Submitting} className="bg-red-600">
          Send Message
        </Button>
        {callback && <button type="button" disabled={Submitting} onClick={callback} className="btn-custom-light ml-3">
          Cancel
        </button>}
                            </FormItem>
                        </FormContainer>
                    </Form>
                )}
            </Formik>
   
  </div>
  )
}

export default ContactForm