
import _ from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { Button, Input, Segment } from 'components/ui'
import { HiCheckCircle, HiMinus, HiPlus } from 'react-icons/hi'
import { useState } from 'react'
import { useEffect } from 'react'
import { setActiveVariant } from '../store/dataSlice'
import { setShoppginCart, toggleshowShipping } from '../store/stateSlice'
import { AddCart } from 'store/cart/actions'
const ProductDetails = () => {
    const dispatch = useDispatch();
    const currentvariant = useSelector((state) => state.productSelection.data.activeVariant);
    const shopInfo = useSelector((state) => state.auth.shop);
    const data = useSelector((state) => state.productSelection.data.productInfo)
    const [VariantInfo, setVariantInfo] = useState({})
    const [activeQuntity, setactiveQuntity] = useState({})
    const [activePrice, setactivePrice] = useState("$0")
    useEffect(() => {
      
        setVariantInfo({color:currentvariant["variant1_id"],size:currentvariant["variant2_id"]})
        setactivePrice(currentvariant["price"])
        if(!activeQuntity[currentvariant["variant1_id"]+"_"+currentvariant["variant2_id"]]){
        setactiveQuntity({...activeQuntity,[currentvariant["variant1_id"]+"_"+currentvariant["variant2_id"]]:1})
    }
      return () => {
        
      }
    }, [currentvariant])
    
    const changeSize = (part)=>{
        let info = VariantInfo;
          
            const fls = _.filter(data["productVariants"], d => d["variant1_id"] === info["color"] && d["variant2_id"] === part)
            console.log(fls);
            setactivePrice(fls[0]["price"])
            dispatch(setActiveVariant(fls[0]))
    }
    const changeColor = (part)=>{
            let info = VariantInfo;
          
            const fls = _.filter(data["productVariants"], d => d["variant2_id"] === info["size"] && d["variant1_id"] === part)
            setactivePrice(fls[0]["price"])
            dispatch(setActiveVariant(fls[0]))
    }
    const updateQuntity = (n)=>{
        let current = activeQuntity[currentvariant["variant1_id"]+"_"+currentvariant["variant2_id"]];
         if(n === -1 && current === 1){
            return false
         }
         current = current + (n)
         setactiveQuntity({...activeQuntity,[currentvariant["variant1_id"]+"_"+currentvariant["variant2_id"]]:current})
    }
    const gotoShipping = () =>{
       
        const fls = _.filter(data["productVariants"], d => d["variant1_id"] === VariantInfo["color"] && d["variant2_id"] === VariantInfo["size"])
        const vrls = _.filter(data["variants"], d => d["variant1_id"] === VariantInfo["color"] && d["variant2_id"] === VariantInfo["size"])
        let qty = activeQuntity[fls[0]["variant1_id"]+"_"+fls[0]["variant2_id"]];
        

        // return;
        let forcart = {
            ...fls[0],
            qty:qty,
            name:data["name"],
            variant:vrls[0]["variant"],
            size:vrls[0]["size"],
            color:vrls[0]["color"],
            colorCode:vrls[0]["colorCode"],
            colorCost:vrls[0]["cost"],

        }
        

        dispatch(AddCart(forcart))
    }
  return (
    <div className='w-1/2'>
    <div className='flex flex-col '>
    {!shopInfo.admin_payment && <span className='mb-3 text-xl font-bold'>${activePrice} USD</span>}
    <span className='mb-3'>Color.</span>
    <div className='flex gap-4 flex-wrap'>
        
    
        {
    _.uniqBy(data.variants, 'color').map((color) => <div key={color.color} title={`${color.color} - ${color.colorCode}`} onClick={()=>changeColor(color["variant1_id"])} className={currentvariant["variant1_id"] && currentvariant["variant1_id"] === color["variant1_id"]?'px-4 py-2 cursor-pointer text-center rounded-2xl bg-black text-white':'px-4 py-2 cursor-pointer text-center rounded-2xl border border-black text-black'} >{color.color.replaceAll("-"," ")}</div>)
        }</div>
        <div className='py-5'></div>
        <span className='mb-3'>Size</span>
    <div className='flex gap-4 flex-wrap'>
        
    
        {
    _.uniqBy(data.variants, 'size').map((size) => <div key={size.size} onClick={()=>changeSize(size["variant2_id"])}   className={currentvariant["variant2_id"] && currentvariant["variant2_id"] === size["variant2_id"]?'px-4 py-2 cursor-pointer text-center rounded-2xl bg-black text-white':'px-4 py-2 cursor-pointer text-center rounded-2xl border border-black text-black'} >{size.size}</div>)
        }</div>
        <div className='py-5'></div>
        <span className='mb-3'>Quantity</span>
        <div className='border border-gray-300 w-1/4  p-3 rounded-lg flex justify-between items-center'>
            <span className='cursor-pointer' onClick={()=>updateQuntity(-1)}><HiMinus size={20}/></span>
            <span className='px-4'>
                <Input className="text-center" value={activeQuntity[currentvariant["variant1_id"]+"_"+currentvariant["variant2_id"]]} />
                </span>
            <span className='cursor-pointer' onClick={()=>updateQuntity(1)}><HiPlus size={20}/></span>

        </div>
        <div className='py-5'></div>
        <div>
        <Button  variant="twoTone" onClick={gotoShipping}
                     className="btn btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]">
                  Add to Cart
        </Button>
        </div>
    </div>
    </div>
  )
}

export default ProductDetails