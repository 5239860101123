import { Card } from 'components/ui'
import React,{useEffect} from 'react'
import reducer from './store'
import { injectReducer } from 'store/index'
import { useSelector } from 'react-redux'
import { API_PREFIX_PATH } from 'constants/route.constant'
import ThemeOne from './themes/ThemeOne'
import { useNavigate,useParams } from 'react-router-dom'

import ThemeTwo from './themes/ThemeTwo'
import useAuth from 'utils/hooks/useAuth'
import Login from 'pages/Login'
import PWReset from 'pages/PWReset'
import ShopAccount from './ShopAccount'

injectReducer('teamShop', reducer)

const Index = () => {
  
  const navigate = useNavigate()
  const params = useParams();
  const { authenticated,verifyGetUser,token } = useAuth();
  const user = useSelector((state) => state.auth.user)
  console.log("authenticated",authenticated);
    useEffect(() => {
      if(authenticated && !user){
        verifyuUser()
      }
      
    
      return () => {
        
      }
    }, [])
    
    const verifyuUser  = async(authenticated)=>{
      
      const result = await verifyGetUser({ token: token})
    }
  const shopInfo = useSelector((state) => state.auth.shop)
  
  return (
    authenticated?
    <ShopAccount />
    :
    shopInfo.shop_theme.id === 1?
    <ThemeOne shopInfo={shopInfo} />
    :
    <ThemeTwo shopInfo={shopInfo}/>
    
  )
}

export default Index