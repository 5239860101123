

import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import display7 from "assets/images/features/display-7.jpg";
import display2 from "assets/images/features/display-2.jpg";
import display3 from "assets/images/features/display-3.jpg";
import { Button, Dialog } from "components/ui";
import { useState } from "react";
import whatisimg from "assets/images/connect/what-is.jpg";
import ConnectBtn from "components/buttons/ConnectBtn";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { BsCheck2Circle } from "react-icons/bs";

const WhyDisplay = () => {
   const [dialogIsOpen, setIsOpen] = useState(false);
   const whyInfo = ["High Profit Margins","Swift Production Fulfillment","Wide Range of Quality Products"];
   useEffect(() => {
    // openDialog()
   
     return () => {
      
     }
   }, [])
   

   const openDialog = () => {
       setIsOpen(true)
   }

   const onDialogClose = (e) => {
       console.log('onDialogClose', e)
       setIsOpen(false)
   }

   const onDialogOk = (e) => {
       console.log('onDialogOk', e)
       setIsOpen(false)
   }
      // Custom navigation arrow components
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        onClick={onClick}
      />
    );
  };

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        onClick={onClick}
      />
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
    pauseOnHover: true,
    centerPadding: 0,
    slidesToScroll: 1,
    centerMode: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    
  };
  return (
    <>
<div className="slider-container">
    
      <div className="item mx-6">
      
       
        <div className="flex items-start justify-end"><img src={display7} className={"w-full"}/></div>
       
      </div>
      <div className="md:px-10 px-5 text-center flex items-center justify-center">
      <div className="flex justify-center h-full flex-col pt-[36px]  w-full">
          <h1 className="font-extrabold text-[28px] md:text-[64px] capitalize mb-2 relative z-10 text-uspfred">USPF: <span className="font-extrabold text-[28px] md:text-[64px] normal-case mb-2 relative z-10 text-uspfdarkblue">The Best Choice for Your
          Print-On-Demand Fulfillment Partner</span></h1>
          
          <div className="text-[18px] md:text-[30px] font-bold text-left mt-6 text-uspfdarkblue leading-normal">
          Selecting the right print-on-demand fulfillment partner can make the difference between launching a highly successful online business and struggling to get off the ground. An ideal POD provider should offer these three essential benefits:
            </div>
            <div className="mt-6 flex flex-col  space-y-3">
            {
                whyInfo.map(item =>
                    
                    <div className="flex items-start flex-col w-full text-[20px] md:text-[36px] ">
                       <div className="flex flex-row space-x-4 items-center">
                        <BsCheck2Circle  color="" className={"text-green-500 textheadicon"}/>
                        <h1 className=" text-uspfred text-left font-bold">{item}</h1></div> 
                       
                    </div>
                   
                    

                )
            }
                </div>
        </div>
        
      </div>
      <div className="md:px-10 px-5 text-center flex items-center justify-center">
      <div className="flex justify-center h-full flex-col pt-[36px]  w-full md:w-1/2 ">
          <h1 className="font-extrabold text-[28px] md:text-[64px] capitalize mb-2 relative z-10 text-uspfred"><span className="font-extrabold text-[28px] md:text-[46px] normal-case mb-2 relative z-10 text-uspfdarkblue">Create your free account
          and start selling today</span></h1>
          
          <div className="text-[18px] md:text-[30px] font-bold text-left ">
          <Link to={`https://usprintfactory.com/app/`} target="_blank" className=" mx-2 mb-2 w-full"><ConnectBtn text={"Get Started"} /></Link>
            </div>
           
        </div>
        
      </div>
    
  </div>
  <Dialog
    isOpen={dialogIsOpen}
    onClose={onDialogClose}
    onRequestClose={onDialogClose}
    width={800}
    closable={false}
>
    <h5 className="mb-4 font-bold">Information Update</h5>
    <p>We're reviewing our prices. As you know, costs are rising all over the world and we're no stranger to these increases. From blank apparel to ink, to shipping costs and more, we've seen a significant increase in costs across the board. We're still going to do our best to keep our services as affordable as possible.
                        Please see the prices mentioned below - will be effective 1/8/2024.</p><br/><p>
Thank you for your understanding - please feel free to reach out if you have any questions or concerns.</p>
                <p><img src="https://uspfdashboard.com/js/price-update.png" width="100%" /></p>
               
    <div className="text-right mt-6">
        <Button
            className="btn btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]"
            variant="plain"
            onClick={onDialogClose}
        >
            Okay
        </Button>
        
    </div>
</Dialog></>
  )
}

export default WhyDisplay