import React, { useEffect, useState } from "react";
import { NavLink, useLocation, matchPath, Link } from "react-router-dom";
import { Button, Drawer,Menu } from 'components/ui'
import { ActionLink } from 'components/shared'
import logo from "assets/images/logo-dark.png";
import mainNavConfig from 'configs/navigation.config/MainNav'
import productsNav from 'configs/navigation.config/products'
import {
  NAV_ITEM_TYPE_TITLE,
  NAV_ITEM_TYPE_COLLAPSE,NAV_ITEM_TYPE_CUSTOM,
  NAV_ITEM_TYPE_ITEM,
} from 'constants/navigation.constant'
import { APP_PREFIX_PATH } from "constants/route.constant";
import { FaBars } from "react-icons/fa";
const Header = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false)

    const openDrawer = () => {
        setIsOpen(true)
    }

    const onDrawerClose = (e) => {
        console.log('onDrawerClose', e)
        setIsOpen(false)
    }



  const menuActive = () => {
    // const menu = document.querySelector(".mobile-menu");
    // menu.classList.toggle("hidden");
    setIsOpen(true)

  };
  const handleToggle = (expanded, e) => {
    console.log('expanded', expanded)
    console.log('event', e)
}
const renderCollaps = (nav,index) => {
  return <Menu.MenuCollapse
  eventKey={`iten-${index}`}
  label="Item 3"
  
>
  <Menu.MenuItem eventKey="item-3-11">Item 3.1</Menu.MenuItem>
  <Menu.MenuItem eventKey="item-3-21">Item 3.2</Menu.MenuItem>
</Menu.MenuCollapse>
}
  return (
    <div>
      <Drawer
                title="Menu"
                isOpen={isOpen}
                onClose={onDrawerClose}
                onRequestClose={onDrawerClose}

                placement={"left"}
            >
                <Menu>
                <a href={"/"} themeColor={false}><Menu.MenuItem eventKey={`item-home`}>Home</Menu.MenuItem></a>
                  {
                    
                    mainNavConfig.map((nav,index) => 
                    {
                      if(nav.type === NAV_ITEM_TYPE_COLLAPSE ){
                        return <Menu.MenuCollapse
                        eventKey={`item-${index}`}
                        label={nav.title}
                        
                    >
                        
                        
                        {
                          nav.subMenu.map((subnav,subkey)=>
                          (
                            <a href={subnav.path} themeColor={false} target={subnav.targetOut?"_blank":"_self"}><Menu.MenuItem className="text-sm font-medium" eventKey={`item-${index}-${subkey}`}>{subnav.title}</Menu.MenuItem></a>
                          ))
                        }
                    </Menu.MenuCollapse>
                       
                        
                      }
                      if(nav.type === NAV_ITEM_TYPE_CUSTOM ){
                        return <Menu.MenuCollapse
                        eventKey={`item-${index}`}
                        label={nav.title}
                        
                    >
                        
                        
                        {
                          productsNav.map((subnav,subkey)=>
                          (
                            <a href={subnav.mobilepath} themeColor={false}><Menu.MenuItem className="text-sm font-medium" eventKey={`item-${index}-${subkey}`}>{subnav.title}</Menu.MenuItem></a>
                          ))
                        }
                       
                    </Menu.MenuCollapse>
                       
                        
                      }
                      else{
                        return <a href={nav.path} themeColor={false}><Menu.MenuItem eventKey={`item-${index}`}>{nav.title}</Menu.MenuItem></a>
                      }
                     
                    }
                    )
                  }
                 <Menu.MenuItem eventKey={`item-full-cat`}><Link className="navbar-brand" to="/products/catalogue"><span className="text-lg font-semibold">View Products Catalogue</span></Link></Menu.MenuItem>
                
            </Menu>
            </Drawer>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light fixed w-full horizontal-nav flex items-center">
          <div className="container h-full px-0">
            <div className="flex items-center justify-between h-full">
              <div className="flex items-center space-x-3 justify-center">
            <button
                onClick={() => menuActive()}
                className="navbar-toggler hidden text-end mobile-menu-button"
              >
                <FaBars size={22} />
              </button>
              <NavLink className="navbar-brand" to="/">
                <img src={logo} className="mr-2 w-[90px] md:w-[110px]" alt="logo" />
              </NavLink>
              </div>

              <div
                className="navbar-collapse flex-1 mobile-menu hidden min-[991px]:flex items-center"
                id="navbarNav"
              >
                <ul className="navbar-nav justify-start horizontal-menu flex-grow px-4">
                  {
                    mainNavConfig.map((nav) =>
                    <li className={nav.type === NAV_ITEM_TYPE_COLLAPSE ?"scroll dropdown group/menu":nav.type === NAV_ITEM_TYPE_CUSTOM ?"scroll dropdown group/menu":"scroll"} key={nav.key}>
                    <a
                     href={nav.path}
                    className={({ isActive }) => {
                        return location.pathname.match(nav.match)
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>{nav.title}</span>
                    </a>
                  
                    {
                      nav.type === NAV_ITEM_TYPE_COLLAPSE && <ul className="dropdown-menu hidden group-hover/menu:block">
                        {
                          nav.subMenu.map((subnav)=>
                          (
                            <li key={subnav.key}>
                            <a className="dropdown-toggle" href={subnav.path} target={subnav.targetOut?"_blank":"_self"}>{subnav.title}</a>
                          </li>
                          ))
                        }
                        
                      </ul> 
                      
                    }
                    {
                      nav.type === NAV_ITEM_TYPE_CUSTOM && <div className="dropdown-menu hidden group-hover/menu:block w-900 fixedin left-0" >
                      <div className=" p-4 w-100 grid grid-cols-5 gap-4">{
                        productsNav.map((product) =>(
<span className="" key={product.key}>
                          <h5 className="font-bold mb-3 text-uspfblue">{product.title}</h5>
                          <ul className="dropdown-menufull group-hover/menu:block">
                          {
                            product.subMenu.map((list) =>(
                              <li key={list.key}>
                              <NavLink to={list.path} type="a">{list.title}</NavLink>
                          </li>
                            ))
                          }
                          </ul>
                      </span>
                        ))
                      }
                       
                      </div>
                      <div className="w-full text-center"><Link className="navbar-brand" to="/products/catalogue"><span className="text-lg font-semibold">View Products Catalogue</span></Link></div>
                    </div>
                    }
                  </li>
                    )
                  }
                  {/* <li className="scroll dropdown group/menu">
                    <NavLink
                      className={({ isActive }) => {
                        return location.pathname.match("/landing")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Products</span>
                    </NavLink>
                    <div className="dropdown-menu hidden group-hover/menu:block w-900 fixedin left-0" >
                      <div className=" p-4 w-100 grid grid-cols-5 gap-4">
                        <span className="">
                          <h5 className="font-bold mb-3 text-uspfblue">othing</h5>
                          <ul className="dropdown-menufull group-hover/menu:block">
                      <li>
                        <NavLink to="/landing/">T-Shirts</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page1">V-Neck T-Shirts</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page2">Tank Top</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page3">Hoodies</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page4">Crewneck Sweatshirt</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page4">Long Sleeve</NavLink>
                      </li>
                        </ul> </span>
                        <span className="">
                          <h5 className="font-bold mb-3 text-uspfblue">Kids's Clothing</h5>
                          <ul className="dropdown-menufull group-hover/menu:block">
                      <li>
                        <NavLink to="/landing/">T-Shirts</NavLink>
                      </li>
                      
                        </ul> </span>
                        <span className="">
                          <h5 className="font-bold mb-3 text-uspfblue">Women's Clothing</h5>
                          <ul className="dropdown-menufull group-hover/menu:block">
                          <li>
                        <NavLink to="/landing/">T-Shirts</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page1">V-Neck T-Shirts</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page2">Tank Top</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page3">Hoodies</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page4">Crewneck Sweatshirt</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page4">Long Sleeve</NavLink>
                      </li>
                        </ul> </span>
                        <span className="">
                          <h5 className="font-bold mb-3 text-uspfblue">Home & Living</h5>
                          <ul className="dropdown-menufull group-hover/menu:block">
                      <li>
                        <NavLink to="/landing/">Decoration</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page1">Unisex T-Shirts</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page2">Womens T-Shirts</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page3">Mens Tank Tops</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page4">Womens V-Necks</NavLink>
                      </li>
                        </ul> </span>
                        <span className="">
                          <h5 className="font-bold mb-3 text-uspfblue">Accessories</h5>
                          <ul className="dropdown-menufull group-hover/menu:block">
                      <li>
                        <NavLink to="/landing/">Mens T-Shirts</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page1">Unisex T-Shirts</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page2">Womens T-Shirts</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page3">Mens Tank Tops</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page4">Womens V-Necks</NavLink>
                      </li>
                        </ul> </span>
                      </div>
                    </div>
                   
                  </li>
                  <li className="scroll dropdown group/menu">
                    <NavLink
                      className={({ isActive }) => {
                        return location.pathname.match("/pages")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Services</span>
                    </NavLink>
                    <ul className="dropdown-menu hidden group-hover/menu:block">
                      <li>
                        <NavLink to="/pages/">About</NavLink>
                      </li>
                      <li>
                        <NavLink to="/pages/servicies">Service</NavLink>
                      </li>
                      <li className="dropdown group/submenu">
                        <NavLink
                          className={({ isActive }) => {
                            return location.pathname.match("/pages/widget")
                              ? "active dropdown-toggle"
                              : "dropdown-toggle";
                          }}
                        >
                          Widget
                        </NavLink>
                        <ul className="dropdown-menu hidden group-hover/submenu:block">
                          <li>
                            <NavLink to="/pages/widget/">Pricing</NavLink>
                          </li>
                          <li>
                            <NavLink to="/pages/widget/testimonials">
                              Testimonial
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/pages/widget/faq">FAQs</NavLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <NavLink to="/pages/contact">Contact</NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="scroll dropdown group/menu">
                    <NavLink
                     
                      className={({ isActive }) => {
                        return location.pathname.match("/account")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Integration</span>
                    </NavLink>
                    <ul className="dropdown-menu hidden group-hover/menu:block">
                      <li>
                        <NavLink to="/account/">Login</NavLink>
                      </li>
                      <li>
                        <NavLink to="/account/register">Ragister</NavLink>
                      </li>
                      <li>
                        <NavLink to="/account/pwreset">Password Reset</NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="scroll  ">
                    <NavLink
                     to="/account/register"
                      className={({ isActive }) => {
                        return location.pathname.match("/documentation")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Docs</span>
                    </NavLink>
                   
                  </li> */}
                 
                </ul>
                <Link className="mr-3" to={"https://usprintfactory.com/app/sign-in?redirectUrl=/"} target="_blank"><button className="btn btn-sm border  border-slate-200 rounded-full font-bold py-2 px-[15px]">
                    Sign In
                  </button>
                  </Link>
                <Link to={"https://usprintfactory.com/app/sign-up"} target="_blank"><button className="btn btn-sm btn-custom navbar-btn rounded-full py-2 px-[15px] font-bold">
                    Sign Up
                    
                  </button>
                  </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
