import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import display1 from "assets/images/features/wslide-1.jpg";
import display2 from "assets/images/features/wslide-2.jpg";
import display3 from "assets/images/features/wslide-3.jpg";
import priceslide from "assets/images/features/priceslide-1.jpg";
import start1 from "assets/images/features/start-1.jpg";
import start2 from "assets/images/features/start-2.jpg";
import start3 from "assets/images/features/start-3.jpg";
import { Button, Dialog } from "components/ui";
import { useState } from "react";
import { Link } from "react-router-dom";
import ConnectBtn from "components/buttons/ConnectBtn";


import {  IoChevronForwardCircleOutline } from "react-icons/io5";

const WhyContentTwo = () => {
   const [dialogIsOpen, setIsOpen] = useState(false)
   useEffect(() => {
    // openDialog()
   
     return () => {
      
     }
   }, [])
   

   const openDialog = () => {
       setIsOpen(true)
   }

   const onDialogClose = (e) => {
       console.log('onDialogClose', e)
       setIsOpen(false)
   }

   const onDialogOk = (e) => {
       console.log('onDialogOk', e)
       setIsOpen(false)
   }
      // Custom navigation arrow components
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        onClick={onClick}
      />
    );
  };

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        onClick={onClick}
      />
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
    pauseOnHover: true,
    centerPadding: 0,
    slidesToScroll: 1,
    arrows:false,
    centerMode: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    
  };
  return (
    <>
<div className="slider-container">
    <Slider {...settings}>
      <div className="item mx-6">
      
        <div className="flex items-start justify-start h-full flex-col pt-[18px]">
          <span className="font-extrabold text-[30px] md:text-[40px] normal-case mb-2 leading-normal relative z-10 text-uspfdarkblue">Maximize Your Profits with Our Great Prices</span>
          
          <div className=" text-[18px] md:text-[30px] leading-snug max-w-[600px] text-uspfred mt-6 font-bold">
          At USPF, we keep prices low to boost your business. Our strong supplier relationships and refined processes let us offer top-quality products at unbeatable prices.
            </div>
            
        </div>
        <div className="flex items-center justify-center"><img src={display1} className={"md:w-3/4 w-full"}/></div>
       
      </div>
      <div className="item mx-6">
       
        <div className="flex items-start justify-start h-full flex-col pt-[18px]">
          
          <span className="font-bold text-[30px] md:text-[36px] capitalize mb-2 relative z-10 text-uspfblue">Super Fast Fullfillment</span>
          <div className=" text-[18px] md:text-[30px] leading-snug max-w-[600px] text-uspfred mt-6 font-bold">
          At USPF, we prioritize speed and efficiency! We process and ship orders within 24-48 hours, often even sooner. Our quick fulfillment keeps your business running smoothly and your customers happy.
            </div>
            
        </div>
        <div className="flex items-center justify-center"><img src={display2} className={"md:w-3/4 w-full"}/></div>
       </div>
      <div className="item  mx-6">
       
        <div className="flex items-start justify-start h-full flex-col pt-[18px]">
        <span className="font-extrabold text-[30px] md:text-[40px] normal-case mb-2 leading-normal relative z-10 text-uspfdarkblue">Extensive Product Selection</span>
          
        <div className=" text-[18px] md:text-[30px] leading-snug max-w-[600px] text-uspfred mt-6 font-bold">
          Discover a vast array of products with USPF! Our extensive catalog offers high-quality items, from trendy apparel to unique accessories, ensuring you find the perfect fit for your brand and meet your customers' diverse needs.
            </div>
            
        </div>
        <div className="flex items-center justify-center"><img src={display3} className={"md:w-3/4 w-full"}/></div>
       
      </div>
      
      
    </Slider>
  </div>
  <div className="flex flex-col items-center mt-5">
  <div><img src={priceslide} className={"md:w-[1250px] w-full"}/></div>
  <div className="flex justify-center h-full flex-col pt-[36px]  w-full md:w-1/2">
          <h1 className="font-extrabold text-center text-[28px] md:text-[64px] capitalize mb-2 relative z-10 text-uspfred"><span className="font-extrabold text-[28px] md:text-[46px] normal-case mb-2 relative z-10 text-uspfdarkblue">Create your free account
          and start selling today</span></h1>
          
          <div className="text-[18px] md:text-[30px] font-bold text-left ">
          <Link to={`https://usprintfactory.com/app/`} target="_blank" className=" mx-2 mb-2 w-full"><ConnectBtn text={"Get Started"} /></Link>
            </div>
           
        </div>
  </div>
  <div className="flex px-3 md:flex-row flex-col md:!items-start items-center mt-8 w-full md:space-y-0 space-y-6 ">
    <div className="flex w-full  md:w-1/2 px-2 flex-col space-y-3">
        <div className="flex items-center justify-center space-x-3 flex-1 "><IoChevronForwardCircleOutline size={30} /><span className="text-2xl font-extrabold text-center">Create USPF Account</span></div>
        <div className="flex items-center justify-center h-[90px]"><img src={start1} className={"h-full"}/></div>
        <div className="text-center">Begin your journey by setting up a USPF
fulfillment store. Next, seamlessly connect your
integrated selling store with USPF.</div>
    </div>
    <div className="flex w-full  md:w-1/2 px-2 flex-col space-y-3">
        <div className="flex items-center justify-center space-x-3 flex-1 "><IoChevronForwardCircleOutline size={30} /><span className="text-2xl font-extrabold text-center">Design and sell Products</span></div>
        <div className="flex items-center justify-center h-[90px]"><img src={start2} className={"h-full"}/></div>
        <div className="text-center">Select products from our catalog, unleash your creativity
with unique designs, and effortlessly add them to your
store. Sit back and await incoming orders as your
personalized products charm your customers.</div>
    </div>
    <div className="flex w-full px-2  md:w-1/2 flex-col space-y-3">
        <div className="flex items-center justify-center space-x-3 flex-1 "><IoChevronForwardCircleOutline size={30} /><span className="text-2xl font-extrabold text-center">Fulfilled by US Print Factory</span></div>
        <div className="flex items-center justify-center h-[90px]"><img src={start3} className={"h-full"}/></div>
        <div className="text-center">Select products from our catalog, unleash your creativity
with unique designs, and effortlessly add them to your
store. Sit back and await incoming orders as your
personalized products charm your customers.</div>
    </div>
    
  </div>
  <div className="flex flex-col items-center mt-5">
  <div className="flex justify-center h-full flex-col pt-[36px]  w-full md:w-1/2">
          <h1 className="font-extrabold text-center text-[28px] md:text-[64px] capitalize mb-2 relative z-10 text-uspfred"><span className="font-extrabold text-[28px] md:text-[46px] normal-case mb-2 relative z-10 text-uspfdarkblue">Create your free account
          and start selling today</span></h1>
          
          <div className="text-[18px] md:text-[30px] font-bold text-left ">
          <Link to={`https://usprintfactory.com/app/`} target="_blank" className=" mx-2 mb-2 w-full"><ConnectBtn text={"Get Started"} /></Link>
            </div>
           
        </div></div>
  <Dialog
    isOpen={dialogIsOpen}
    onClose={onDialogClose}
    onRequestClose={onDialogClose}
    width={800}
    closable={false}
>
    <h5 className="mb-4 font-bold">Information Update</h5>
    <p>We're reviewing our prices. As you know, costs are rising all over the world and we're no stranger to these increases. From blank apparel to ink, to shipping costs and more, we've seen a significant increase in costs across the board. We're still going to do our best to keep our services as affordable as possible.
                        Please see the prices mentioned below - will be effective 1/8/2024.</p><br/><p>
Thank you for your understanding - please feel free to reach out if you have any questions or concerns.</p>
                <p><img src="https://uspfdashboard.com/js/price-update.png" width="100%" /></p>
               
    <div className="text-right mt-6">
        <Button
            className="btn btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]"
            variant="plain"
            onClick={onDialogClose}
        >
            Okay
        </Button>
        
    </div>
</Dialog></>
  )
}

export default WhyContentTwo