import React from 'react'

import LandingPageLayout from "./layout/LandingPageLayout";
import MainLayout from "./layout/MainLayout";
import PageLayout from "./layout/PageLayout";
import About from "./pages/About";

import ContactPage from "./pages/ContactPage";

import FAQPage from "./pages/FAQPage";
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
import LandingPageTwo from "./pages/LandingPageTwo";
import Login from "./pages/Login";
import Overview from "./pages/Overview";
import PricingPage from "./pages/PricingPage";
import PWReset from "./pages/PWReset";
import Ragister from "./pages/Ragister";
import Servicies from "./pages/Servicies";
import Testimonials from "./pages/Testimonials";
import { productsroutes } from "configs/routes.config";
import { APP_PREFIX_PATH } from "constants/route.constant";

import Page404Layout from "layout/Page404Layout";
import TermsService from "pages/TermsService";
import PrivacyPolicy from "pages/PrivacyPolicy";
import UsagePolicy from "pages/UsagePolicy";
import Shop from "views/shops"
import ProductInfo from "views/shops/product"
import ProductsCatalogue from "pages/ProductsCatalogue";
import ShopPageLayout from "layout/ShopPageLayout";
import useAuth from 'utils/hooks/useAuth'
import UserOrders from 'views/shops/orders';
import CheckoutShop from 'views/shops/checkout';
import OrderInfoLayout from 'layout/OrderInfoLayout';
import OrderInfo from 'views/shops/orders/OrderInfo';

const MainLayoutOuter = () => {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <LandingPageLayout />,
          children: productsroutes,
        },
        {
          path: "/products/catalogue",
          element: <PageLayout />,
          children: [
            {
              path: "",
              element: <ProductsCatalogue />,
            },
          ]
          
        },
        {
          path: APP_PREFIX_PATH+"/pages",
          element: <PageLayout />,
          children: [
            {
              path: "",
              element: <About />,
            },
            {
              path: "servicies",
              element: <Servicies />,
            },
            {
              path: "pricing",
              element: <PricingPage />,
            },
            {
              path: "faq",
              element: <FAQPage />,
            },
            {
              path: "terms-of-service",
              element: <TermsService />,
            },
            {
              path: "privacy-policy",
              element: <PrivacyPolicy />,
            },
            {
              path: "usage-policy",
              element: <UsagePolicy />,
            },
           
            {
              path: "contact",
              element: <ContactPage />,
            },
          ],
        },
        {
          path: "/:shop",
          element: <ShopPageLayout />,
          children: [
            {
              path: "/:shop",
              element: <Shop />,
              
            },
            {
              path: "/:shop/products",
              element: <Shop />,
              
            },
            {
              path: "/:shop/orders",
              element: <UserOrders />,
              
            },
            {
              path: "/:shop/join",
              element: <Ragister />,
              
            },
            {
                path: "/:shop/login",
                element: <Login />,
                
            },
            {
              path: "/:shop/product/:id",
              element: <ProductInfo />,
              
            },
           
            {
              path: "/:shop/checkout",
              element: <CheckoutShop />,
              
            },
          ]
        },
        {
          path: "/:shop",
          element: <OrderInfoLayout />,
          children:[
            {
              path: "/:shop/order/:id",
              element: <OrderInfo />,
              
            },
          ]
        }
        
      ],
    },
    
    {
      path:"*",
      element: <MainLayout />,
      children: [
        {
          path: "*",
          element: <Page404Layout />,
          
        },
      ]
    }
  ]);
  return (
    <RouterProvider forceRefresh={true} router={router} />
  )
}

export default MainLayoutOuter